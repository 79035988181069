@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
@import 'app/assets/styles/_common.scss';
@import 'app/assets/styles/variables';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-content {
  min-height: 90vh;
  padding: 78px 15px 20px;
  ::-webkit-scrollbar {
    z-index: 30;
    width: 10px;
    height: 12px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(187, 184, 184, 0.466);
    border-left: 1px solid #c1c1c1;
    border-radius: 20px;
    box-shadow: none;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.281);
    border-radius: 20px;
    box-shadow: none;
    border: 0;
    &:hover {
      background-color: rgba(7, 7, 7, 0.521);;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
