@import "../../assets/styles/variables";
.left-menu a {
  border: 0.1rem solid #fdfdfd !important;
  background-color: none;
  color: white;
}

.left-menu .nav-item .nav-link {
  background: none;
  color: rgba(0, 0, 0, 0.481);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.left-menu .nav-item .nav-link:hover {
  background-color: $primary_hover !important;
  color: white !important;
}

.left-menu .nav-item .nav-link.active {
  background: $primary_hover !important;
  color: white !important;
}
