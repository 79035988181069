@import "variables";

body {
  color: $text-color;
}
.relative {
  position: relative;
}

.primary-bg {
  background-color: $primary;
}

.primary-text {
  color: $primary;
}

button.primary-bg {
  &:hover,
  &:active {
    background-color: darken($primary, 5%);
  }
}

.page-title {
  align-items: center;
  h1 {
    line-height: 54px;
    font-size: 24px !important;
    font-weight: 400;
  }
}

.form-control.is-valid,
.was-validated {
  .form-control:valid,
  .form-control:invalid {
    background-image: none;
  }
}

.action-btn-icon {
  font-size: 12px;
  padding-right: 3px;
}

.action-btn {
  font-weight: 500;
  font-size: 0.8rem;
}

.approve-btn {
  background-color: $primary;
}

.btn-success:hover {
  background-color: darken($primary_hover, 10%) !important;
  border-color: darken($primary, 10%);
}

.text-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  span {
    font-weight: bold;
  }
}

.toast {
  font-size: 15px;
  width: 380px !important;
}

.toast-body {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.custom-pagination {
  margin-top: 10px;
  .pagination {
    float: right;
    color: #6C757D;
    .page-item.active .page-link {
      background-color: #6C757D; 
      color: #ffffff; 
      border: #6C757D;
    }
    .page-item .page-link {
      color: #6C757D;
    }
  }
}

.width100 {
  width: 100%;
}

.longString {
  word-break: break-all;
}

.bold {
  font-weight: 500;
}

.radioButton {
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: gray;
    border-color: gray;
  }
}

.btn-group.btn-group-toggle {
  z-index: 0;
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: "#E9ECEF";
}
// Adding default background color to individual drpdown item

.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item.active {
  background-color: #6C757D;
}

.dropdown.show .dropdown-menu {
  /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
  max-height: 500px;
  opacity: 1;
}

.second-level-modal {
  z-index: 1050;
}

.custom-file-label {
  z-index: 0;
}

.btn-success {
  background-color: $primary !important;
  border: 2px solid $primary !important;
}

.btn-outline-success {
  color: $primary;
  border: 1px solid $primary !important;
}

.btn-outline-success:hover {
  background-color: $primary !important;
  border: 1px solid $primary !important;
  color: #fff;
}




