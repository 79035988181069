.outer-container {
  background: #f4f7fe;
  border-radius: 10px;
}

.fancy {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #afafaf7a;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.fancy span {
  // background: #f4f7fe;
  background: white;
  padding: 0 10px;
}

// .unique-count-container {
//   width: 450px;
//   // height: 100px;
//   // padding: 10px 20px;
//   margin-bottom: 10px;
//   background-color: white;
//   border-radius: 10px;
//   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
//   .text {
//     display: inline-block;
//     border-top-left-radius: 10px;
//     border-bottom-left-radius: 10px;
//     line-height: 1.4;
//     background-color: #08279c;
//     padding: 25px;
//     // padding-top: 20px;
//     // padding-bottom: 20px;
//     font-size: 18px;
//     color: white;
//   }
//   .count {
//     display: inline-block;
//     color: gray;
//     font-size: 40px;
//     line-height: 1.4;
//   }
// }

// .dashboard-item-container {
//   height: 100px;
//   min-width: 150px;
//   padding: 10px;
//   border-radius: 10px;
//   background: #ffff;
//   display: inline-block;
//   margin-right: 20px;
//   box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
//     0 4px 6px -2px rgba(0, 0, 0, 0.05);
//   transition: background 0.2s ease-in-out;
//   .heading {
//     margin-bottom: 10px;
//     font-weight: 500;
//     color: #7a869a;
//   }
//   .count {
//     font-size: 0.875rem;
//     font-size: 30px;
//     color: #172b4d;
//   }
//   &:hover {
//     color: white;
//     background: #08279c;
//     cursor: pointer;
//     .heading,
//     .count {
//       color: white;
//     }
//   }
// }

// .table100 {
//   border-radius: 10px;
//   overflow: hidden;
//   // box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
//   // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
//   box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
//     0 4px 6px -2px rgba(0, 0, 0, 0.05);
//   table {
//     width: 100%;
//   }
//   th {
//     font-weight: normal;
//     font-size: 18px;
//     text-align: center;
//     color: white;
//     line-height: 1.4;
//     // background-color: #6c7ae0;
//     background-color: #08279c;
//     padding-top: 20px;
//     padding-bottom: 20px;
//     width: 20%;
//   }
//   td {
//     // font-family: Lato-Regular;
//     font-size: 40px;
//     background-color: white;
//     color: gray;
//     line-height: 1.4;
//     padding-top: 16px;
//     padding-bottom: 16px;
//     text-align: center;
//     width: 20%;
//   }
// }

// .table70 {
//   margin: 0 10% 0 10%;
// }

.primaryContainer {
  border-radius: 10px;
  text-align: center;
  background: white;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  h5 {
    font-weight: 400;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // border-bottom: 1px solid #afafaf7a;
  }
  .count-wrapper {
    padding-top: 10px;
  }
}

.primaryContainer.summary {
  height: 200px;
  margin-bottom: 20px;
  h5 {
    // background: #f4f7fe;
    background: #08279c;
    color: white;
  }
  .count-container {
    width: 50%;
  }
}

.primaryContainer.details {
  height: 390px;
  margin-bottom: 20px;
  .count-container {
    width: 20%;
  }
  h6 {
    padding-top: 30px;
  }
}

.primaryContainer.handler {
  height: 380px;
  margin-bottom: 20px;
  h5 {
    color: white;
    background: #ed545c;
    // background: #ffecdc;
  }
  .count-wrapper {
    padding-top: 0px;
    .count-container {
      display: block;
      width: 100%;
    }
  }
}

.primaryContainer.sync {
  height: 210px;
  overflow: auto;
  h5 {
    padding: 15px;
    color: white;
    background: #008376;
    // background: #e5fcdc;
  }
  table {
    text-align: left;
    margin-top: 20px;
  }
}

.count-container {
  display: inline-block;
  .count {
    font-size: 40px;
    color: gray;
    line-height: 1.4;
  }
  .text {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.4;
    color: #172b4d;
  }
}
