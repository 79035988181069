@import '../../assets/styles/variables';

.navbar {
  background: #fff !important;
  border-bottom: 1px solid rgba(0,0,0,.14) !important;
  box-shadow: 0 0.125rem 0.3125rem rgba(0,0,0,.26);
  .navbar-nav {
    font-weight: 500 !important;
  }
  .navbar-collapse {
    background: #fff !important;
    // margin-top: 16px;
  }
  .sub-head {
    color: rgba(0,0,0,.5);
  }
}


.fixed-top {
  height: 64px !important;
}

.nav-link {
  display: block;
  padding: 1rem 1rem !important;
  text-decoration: none;
  background-color: transparent;
  // border-bottom: 1px solid rgba(94, 54, 54, 0.14);
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: $primary;
}

@media (min-width: 576px) {
  .nav-link {
    padding-right: 1.5rem !important;
    padding-left: .5rem !important;
  }
}

.nav-username {
  font-size: 18px;
}

.nav-link.active {
  color: $primary !important;
  }
