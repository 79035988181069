.calendarOverlay {
  background-color: #fff;
 // height: 300px;
//  width: 700px;
  right: 0;
  position: absolute;
  z-index: 9;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  .react-calendar {
    border: none;
    border-radius: 5px;
    display: inline-block;
    transition: display 0.5s linear;
    // box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
}

.border-right {
  border-right: 1px solid #d8d7d7;
}